import React, { useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingPopup from '../components/popups/LoadingPopup';
import Notifications from '../components/Notifications';
import MelodySamplesUploadPopup from '../components/popups/melodySamplesUpload'; // Adjust the path if necessary
import { displayError, displaySuccess } from '../components/utils';
import { FaFileAudio, FaTimes, FaQuestionCircle, FaPlay, FaPause } from 'react-icons/fa';
import { authFetch } from '../authFetch';


const SamplesUpload = () => {
  const { getIdTokenClaims } = useAuth0();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [instruments, setInstruments] = useState([]);
  const [artists, setArtists] = useState([]);
  const [genres, setGenres] = useState([]);
  const [moods, setMoods] = useState([]);
  const [tempoStyle, setTempoStyle] = useState([]);
  const [bpm, setBpm] = useState('');
  const [key, setKey] = useState('');
  const [mode, setMode] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [errorMessages, setErrorMessages] = useState('');
  const [successMessages, setSuccessMessages] = useState('');
  //Playing File State
  const [playingFile, setPlayingFile] = useState(null);
  const audioRef = useRef(null);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const wavFiles = files.filter(file => file.type === 'audio/wav');

    if (wavFiles.length !== files.length) {
      setErrorMessage('Only WAV files are allowed.');
    } else {
      setErrorMessage('');
      setSelectedFiles(prevFiles => [...prevFiles, ...wavFiles.map(file => ({
        file,
        url: URL.createObjectURL(file)
      }))]);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const wavFiles = files.filter(file => file.type === 'audio/wav');

    if (wavFiles.length !== files.length) {
      setErrorMessage('Only WAV files are allowed.');
    } else {
      setErrorMessage('');
      setSelectedFiles(prevFiles => [...prevFiles, ...wavFiles.map(file => ({
        file,
        url: URL.createObjectURL(file)
      }))]);
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newFiles);
    if (playingFile === index) {
      setPlayingFile(null);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  };

  const handlePlayPause = (index) => {
    if (playingFile === index) {
      audioRef.current.pause();
      setPlayingFile(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      audioRef.current.src = selectedFiles[index].url;
      audioRef.current.play();
      setPlayingFile(index);
    }
  };

  const handleTagChange = (e, setter, tagArray) => {
    if (e.key === 'Enter' || e.key === ',') {
      const value = e.target.value.replace(/,$/, '').trim();
      if (value && !tagArray.includes(value)) {
        setter([...tagArray, value]);
      }
      e.target.value = '';
      e.preventDefault();
    }
  };

  const handleRemoveTag = (setter, tagArray, index) => {
    const newArray = tagArray.filter((_, i) => i !== index);
    setter(newArray);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = (e) => {
    if (e.target.id !== 'file') {
      document.getElementById('file').click();
    }
  };

  const handleBpmChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setBpm(value);
    }
  };


  const handleHowToUploadClick = () => {
    setIsPopupOpen(true); // Open the popup
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };


  const handleSubmit = async () => {
    setIsLoading(true);
    const idTokenClaims = await getIdTokenClaims();
    const idToken = idTokenClaims.__raw;

    // Convert the files to base64 strings
    const filesData = await Promise.all(
      selectedFiles.map(fileObj => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve({ name: fileObj.file.name, data: reader.result });
          reader.onerror = error => reject(error);
          reader.readAsDataURL(fileObj.file);
        });
      })
    );

    const payload = {
      files: filesData,
      instruments,
      artists,
      genres,
      moods,
      tempo_style: tempoStyle,
      bpm,
      key,
      mode
    };

    try {
      const responseData = await authFetch(
        `${process.env.REACT_APP_BACKEND_URL}/uploadingmelsamples`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        getAccessTokenSilently
      );

      console.log('Response:', responseData);
      displaySuccess(setSuccessMessages)('Files uploaded successfully!');
    } catch (error) {
      console.error('Error:', error);
      if (error.status === 403 || error.status === 401) {
        // Handle unauthorized access
        displayError(setErrorMessages)('Unauthorized access. Please log in again.');
      } else {
        displayError(setErrorMessages)('Failed to upload files. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
            <Notifications
        errorMessages={errorMessages}
        successMessages={successMessages}
        setErrorMessages={setErrorMessages}
        setSuccessMessages={setSuccessMessages}
      />
      <header className="text-center py-5 bg-transparent">
        <h1 className="text-4xl font-bold mb-4">Upload Your Melody Samples</h1>
      </header>

      <section className="container mx-auto flex flex-col items-center">
        <div className="w-full max-w-xl bg-gray-800 shadow-md rounded px-10 pt-8 pb-10 mb-6">

            {/* How to Upload Button */}
            <div className="mb-4 text-right">
            <button
                className="btn flex items-center text-white bg-blue-500 hover:bg-blue-700 transition-colors"
                onClick={handleHowToUploadClick}
            >
                <FaQuestionCircle className="mr-2" /> How to upload
            </button>
            </div>

          
      {/* File Uploader */}
      <div className="mb-6">
        <label className="block text-blue-300 text-sm font-bold mb-2">Melody Files (WAV only):</label>
        <div
          className="border-2 border-dashed border-blue-300 rounded w-full px-4 py-12 text-center cursor-pointer hover:bg-gray-700"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={handleClick}
        >
          <p className="text-blue-300 mb-2">Drag and drop files here, or click to select files</p>
          {selectedFiles.length > 0 && (
            <div className="flex flex-wrap justify-center mt-2">
              {selectedFiles.map(({ file }, index) => (
                <div 
                  key={index} 
                  className="relative flex items-center px-2 py-1 bg-blue-500 text-white rounded mb-2 mr-2"
                >
                  <FaFileAudio className="mr-2" /> {file.name}
                  <button
                    className="ml-2 focus:outline-none"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePlayPause(index);
                    }}
                  >
                    {playingFile === index ? <FaPause /> : <FaPlay />}
                  </button>
                  <button
                    className="ml-2 focus:outline-none"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveFile(index);
                    }}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
            </div>
          )}
          {errorMessage && (
            <p className="text-red-500 mt-2">{errorMessage}</p>
          )}
        </div>
        <input
          type="file"
          id="file"
          multiple
          accept=".wav"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      {/* Audio player */}
      <audio ref={audioRef} onEnded={() => setPlayingFile(null)} />
        
          {/* Artists */}
          <div className="mb-6">
            <label className="block text-blue-300 text-sm font-bold mb-2">Artists:</label>
            <input
              type="text"
              placeholder="Artists being, Drake, Taylor Swift, etc."
              className="border rounded w-full px-2 py-2 mt-2 mb-2"
              onKeyUp={(e) => handleTagChange(e, setArtists, artists)}
            />
            <div className="flex flex-wrap">
              {artists.map((artist, index) => (
                <div 
                  key={index} 
                  className="relative px-2 py-1 bg-blue-500 text-white mr-1 rounded mb-1 cursor-pointer"
                  onClick={() => handleRemoveTag(setArtists, artists, index)}
                >
                  {artist}
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity rounded flex items-center justify-center">
                    <FaTimes className="text-white" />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Instruments */}
          <div className="mb-6">
            <label className="block text-blue-300 text-sm font-bold mb-2">Instruments:</label>
            <input
              type="text"
              placeholder="Instruments being, Guitar, Piano, Drums, etc."
              className="border rounded w-full px-2 py-2 mt-2 mb-2"
              onKeyUp={(e) => handleTagChange(e, setInstruments, instruments)}
            />
            <div className="flex flex-wrap">
              {instruments.map((instrument, index) => (
                <div 
                  key={index} 
                  className="relative px-2 py-1 bg-blue-500 text-white mr-1 rounded mb-1 cursor-pointer"
                  onClick={() => handleRemoveTag(setInstruments, instruments, index)}
                >
                  {instrument}
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity rounded flex items-center justify-center">
                    <FaTimes className="text-white" />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Genres */}
          <div className="mb-6">
            <label className="block text-blue-300 text-sm font-bold mb-2">Genres:</label>
            <input
              type="text"
              placeholder="Genres being, Hip-Hop, Pop, Rock, Alternative, etc."
              className="border rounded w-full px-2 py-2 mt-2 mb-2"
              onKeyUp={(e) => handleTagChange(e, setGenres, genres)}
            />
            <div className="flex flex-wrap">
              {genres.map((genre, index) => (
                <div 
                  key={index} 
                  className="relative px-2 py-1 bg-blue-500 text-white mr-1 rounded mb-1 cursor-pointer"
                  onClick={() => handleRemoveTag(setGenres, genres, index)}
                >
                  {genre}
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity rounded flex items-center justify-center">
                    <FaTimes className="text-white" />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Moods */}
          <div className="mb-6">
            <label className="block text-blue-300 text-sm font-bold mb-2">Moods:</label>
            <input
              type="text"
              placeholder="Moods being, Sad, Epic, Happy, Ambient, etc."
              className="border rounded w-full px-2 py-2 mt-2 mb-2"
              onKeyUp={(e) => handleTagChange(e, setMoods, moods)}
            />
            <div className="flex flex-wrap">
              {moods.map((mood, index) => (
                <div 
                  key={index} 
                  className="relative px-2 py-1 bg-blue-500 text-white mr-1 rounded mb-1 cursor-pointer"
                  onClick={() => handleRemoveTag(setMoods, moods, index)}
                >
                  {mood}
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity rounded flex items-center justify-center">
                    <FaTimes className="text-white" />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Tempo Style */}
          <div className="mb-6">
            <label className="block text-blue-300 text-sm font-bold mb-2">Tempo Style:</label>
            <input
              type="text"
              placeholder="Tempo styles being, Fast, Slow, Bouncy, etc."
              className="border rounded w-full px-2 py-2 mt-2 mb-2"
              onKeyUp={(e) => handleTagChange(e, setTempoStyle, tempoStyle)}
            />
            <div className="flex flex-wrap">
              {tempoStyle.map((style, index) => (
                <div 
                  key={index} 
                  className="relative px-2 py-1 bg-blue-500 text-white mr-1 rounded mb-1 cursor-pointer"
                  onClick={() => handleRemoveTag(setTempoStyle, tempoStyle, index)}
                >
                  {style}
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity rounded flex items-center justify-center">
                    <FaTimes className="text-white" />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Other Form Elements */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div>
              <label className="block text-blue-300 text-sm font-bold mb-2" htmlFor="bpm">
                BPM:
              </label>
              <input
                type="text"
                id="bpm"
                className="border rounded w-full px-2 py-2"
                value={bpm}
                onChange={handleBpmChange}
              />
            </div>

            <div>
              <label className="block text-blue-300 text-sm font-bold mb-2" htmlFor="key">
                Key:
              </label>
              <select
                id="key"
                className="border rounded w-full px-2 py-2"
                value={key}
                onChange={(e) => setKey(e.target.value)}
              >
                <option value="">Select a Key</option>
                {['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'].map((note) => (
                  <option key={note} value={note}>
                    {note}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-blue-300 text-sm font-bold mb-2" htmlFor="mode">
                Mode:
              </label>
              <select
                id="mode"
                className="border rounded w-full px-2 py-2"
                value={mode}
                onChange={(e) => setMode(e.target.value)}
              >
                <option value="">Select a Mode</option>
                <option value="Major">Major</option>
                <option value="Minor">Minor</option>
              </select>
            </div>
          </div>

          <button
            className="w-full py-3 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-colors"
            onClick={handleSubmit}
            disabled={!bpm || !artists || !key || !mode || selectedFiles.length === 0}
          >
            Submit
          </button>
        </div>
      </section>
            {/* Add the LoadingPopup component */}
            <LoadingPopup isOpen={isLoading} />
            
            {/* How to Upload Popup */}
            <MelodySamplesUploadPopup isOpen={isPopupOpen} onClose={handleClosePopup} />

    </div>
  );
};

export default SamplesUpload;