// src/components/popups/melodySamplesUpload.js
import React from 'react';
import { FaTimes } from 'react-icons/fa';


const MelodySamplesUploadPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="modal-box relative bg-white text-black p-8 rounded shadow-lg">
        <button
          className="absolute top-0 right-0 mt-2 mr-2 flex items-center justify-center w-8 h-8 rounded-full text-gray-700 hover:bg-red-500 hover:text-white transition-colors"
          onClick={onClose}
        >
          <FaTimes size={16} />
        </button>
        <h3 className="font-bold text-lg mb-4">How to Upload Drums Samples</h3>
        <p className="mb-4">
          Instructions on how to upload files:
          <ol className="list-decimal ml-6 mt-2">
            <li>Only WAV files are allowed.</li>
            <li>Keep your WAV files 8 bars in length</li>
            <li>Drag and drop files into the box or click to select files.</li>
            <li>Use the fields below to add tags and additional metadata.</li>
          </ol>
        </p>
        <button
          className="btn btn-primary w-full"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default MelodySamplesUploadPopup;
