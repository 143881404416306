import React, { useRef, useEffect } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import logo from '../Images/logo192.png'; // Import the logo

const Layout = ({ isAuthenticated, tokensRemaining, showDropdown, setShowDropdown, handleLogout, handleLoginClick }) => {
  const location = useLocation();
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Handler for clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDropdown]);

  // Handler for dropdown item clicks
  const handleDropdownClick = (callback) => (e) => {
    if (callback) {
      e.preventDefault();
      callback();
    }
    setShowDropdown(false); // Close dropdown after selection
  };

  return (
    <div>
      {location.pathname !== '/welcome' && (
        <header>
          <h2>
          <Link to='/' className="header-link">
              <img 
                src={logo} 
                alt="YSM.AI Logo" 
                className="header-logo"
              />
            </Link>
          </h2>
          <div className="header-left">
            <div className="tokens-remaining">
              {isAuthenticated && (
                <>
                  <span>Tokens Remaining: {tokensRemaining}</span>
                </>
              )}
            </div>
          </div>
          <div className="account-dropdown" ref={dropdownRef}>
            <button onClick={() => setShowDropdown(!showDropdown)}>Account</button>
            {showDropdown && (
              <div className="dropdown-content">
                {isAuthenticated ? (
                  <>
                    <a href="/" onClick={handleDropdownClick(handleLogout)}>Logout</a>
                    <Link to="/account/profile" onClick={handleDropdownClick()}>My Account</Link>
                    <Link to="/subscriptions" onClick={handleDropdownClick()}>Subscriptions</Link>
                    <Link to="/choice" onClick={handleDropdownClick()}>Contribute</Link>
                  </>
                ) : (
                  <>
                    <a href="/" onClick={handleDropdownClick(handleLoginClick)}>Login/Register</a>
                  </>
                )}
              </div>
            )}
          </div>
        </header>
      )}
      <Outlet />
    </div>
  );
};

export default Layout;
