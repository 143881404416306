import React, { useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingPopup from '../components/popups/LoadingPopup';
import Notifications from '../components/Notifications';
import DrumUploadPopup from '../components/popups/drumSamplesUpload';
import { displayError, displaySuccess } from '../components/utils';
import { FaFileAudio, FaTimes, FaQuestionCircle, FaPlay, FaPause } from 'react-icons/fa';
import { authFetch } from '../authFetch';
import { Drum } from 'lucide-react';

const DrumUpload = () => {
    const { getIdTokenClaims } = useAuth0();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [artists, setArtists] = useState([]);
    const [instruments, setInstruments] = useState([]);
    const [genres, setGenres] = useState([]);
    const [moods, setMoods] = useState([]);
    const [tempoStyle, setTempoStyle] = useState([]);
    const [bpm, setBpm] = useState('');
    const [key, setKey] = useState('');
    const [mode, setMode] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 
    const [errorMessages, setErrorMessages] = useState('');
    const [successMessages, setSuccessMessages] = useState('');
    //Playing File State
    const [playingFile, setPlayingFile] = useState(null);
    const audioRef = useRef(null);

    // Add new state for drum type
    const [drumType, setDrumType] = useState('');

    // Available drum types
    const drumTypes = [
        '808',
        'Kick',
        'Snare',
        'Hi-Hat',
        'Clap',
        'Percussion',
        // Add more as needed
    ];

    // Show Key and Mode only for 808s
    const show808Fields = drumType === '808';

    // Add state for multiple drum entries
    const [drumEntries, setDrumEntries] = useState([
        {
            id: Date.now(),
            file: null,
            drumType: '',
            genres: [],
            artists: [],
            instruments: [],
            moods: [],
            tempoStyle: [],
            bpm: '',
            key: '',
            mode: ''
        }
    ]);

    // Handle adding new row
    const addNewRow = () => {
        setDrumEntries([...drumEntries, {
            id: Date.now(),
            file: null,
            drumType: '',
            genres: [],
            artists: [],
            instruments: [],
            moods: [],
            tempoStyle: [],
            bpm: '',
            key: '',
            mode: ''
        }]);
    };

    // Handle removing row
    const removeRow = (id) => {
        setDrumEntries(drumEntries.filter(entry => entry.id !== id));
    };

    // Handle field updates
    const updateEntry = (id, field, value) => {
        setDrumEntries(drumEntries.map(entry => 
            entry.id === id ? { ...entry, [field]: value } : entry
        ));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const wavFiles = files.filter(file => file.type === 'audio/wav');

        if (wavFiles.length !== files.length) {
            setErrorMessage('Only WAV files are allowed.');
        } else {
            setErrorMessage('');
            setSelectedFiles(prevFiles => [...prevFiles, ...wavFiles.map(file => ({
                file,
                url: URL.createObjectURL(file)
            }))]);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        const wavFiles = files.filter(file => file.type === 'audio/wav');
    
        if (wavFiles.length !== files.length) {
          setErrorMessage('Only WAV files are allowed.');
        } else {
          setErrorMessage('');
          setSelectedFiles(prevFiles => [...prevFiles, ...wavFiles.map(file => ({
            file,
            url: URL.createObjectURL(file)
          }))]);
        }
      };

      const handleRemoveFile = (index) => {
        const newFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(newFiles);
        if (playingFile === index) {
          setPlayingFile(null);
          if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
          }
        }
      };
    
      const handlePlayPause = (entryId) => {
        if (playingFile === entryId) {
            audioRef.current.pause();
            setPlayingFile(null);
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
            }
            const entry = drumEntries.find(e => e.id === entryId);
            if (entry && entry.file) {
                audioRef.current = new Audio(URL.createObjectURL(entry.file));
                audioRef.current.play();
                setPlayingFile(entryId);
                
                // Auto-stop when audio ends
                audioRef.current.onended = () => {
                    setPlayingFile(null);
                };
            }
        }
    };
    
      const handleTagChange = (e, setter, tagArray) => {
        if (e.key === 'Enter' || e.key === ',') {
          const value = e.target.value.replace(/,$/, '').trim();
          if (value && !tagArray.includes(value)) {
            setter([...tagArray, value]);
          }
          e.target.value = '';
          e.preventDefault();
        }
      };


      const handleRemoveTag = (setter, tagArray, index) => {
        const newArray = tagArray.filter((_, i) => i !== index);
        setter(newArray);
      };
    
      const handleDragOver = (e) => {
        e.preventDefault();
      };
    
      const handleClick = (e) => {
        if (e.target.id !== 'file') {
          document.getElementById('file').click();
        }
      };
    
      const handleBpmChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
          setBpm(value);
        }
      };
    
    
      const handleHowToUploadClick = () => {
        setIsPopupOpen(true); // Open the popup
      };
    
      const handleClosePopup = () => {
        setIsPopupOpen(false); // Close the popup
      };


      const handleSubmit = async () => {
        setIsLoading(true);
        const idTokenClaims = await getIdTokenClaims();
        const idToken = idTokenClaims.__raw;
    
        // Convert the files to base64 strings
        const filesData = await Promise.all(
          selectedFiles.map(fileObj => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => resolve({ name: fileObj.file.name, data: reader.result });
              reader.onerror = error => reject(error);
              reader.readAsDataURL(fileObj.file);
            });
          })
        );
    
        const payload = {
          files: filesData,
          instruments,
          artists,
          genres,
          moods,
          tempoStyle,
          bpm,
          key,
          mode
        };
    
        try {
          const responseData = await authFetch(
            `${process.env.REACT_APP_BACKEND_URL}/uploadingmelsamples`,
            {
              method: 'POST',
              body: JSON.stringify(payload),
            },
            getAccessTokenSilently
          );
    
          console.log('Response:', responseData);
          displaySuccess(setSuccessMessages)('Files uploaded successfully!');
        } catch (error) {
          console.error('Error:', error);
          if (error.status === 403 || error.status === 401) {
            // Handle unauthorized access
            displayError(setErrorMessages)('Unauthorized access. Please log in again.');
          } else {
            displayError(setErrorMessages)('Failed to upload files. Please try again.');
          }
        } finally {
          setIsLoading(false);
        }
      };
return (
    <div className="min-h-screen p-3 pt-6 bg-gray-900">
        <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold text-white">Drum Sample Uploads</h1>
            <button
                className="flex items-center px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors text-sm"
                onClick={handleHowToUploadClick}
            >
                <FaQuestionCircle className="mr-1" /> How to Upload
            </button>
        </div>

        <div className="w-full bg-gray-800 rounded-lg shadow-xl p-3">
            <div className="grid grid-cols-[0.7fr,0.7fr,0.7fr,0.7fr,0.7fr,0.7fr,0.7fr,0.5fr,0.4fr,0.4fr] gap-1 mb-2 text-white text-xs font-bold border-b border-gray-700 pb-2">
                <div>File</div>
                <div>Type</div>
                <div>Genres</div>
                <div>Artists</div>
                <div>Instruments</div>
                <div>Moods</div>
                <div>Tempo</div>
                <div>BPM</div>
                <div>Key</div>
                <div>Mode</div>
            </div>

            {drumEntries.map((entry, index) => (
                <div key={entry.id} className="relative">
                    <div className="grid grid-cols-[0.7fr,0.7fr,0.7fr,0.7fr,0.7fr,0.7fr,0.7fr,0.5fr,0.4fr,0.4fr] gap-1 mb-1 items-center bg-gray-700 p-1 rounded">
                        <div className="relative">
                            <input
                                type="file"
                                accept=".wav"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        updateEntry(entry.id, 'file', file);
                                    }
                                }}
                                className="hidden"
                                id={`file-${entry.id}`}
                            />
                            <label
                                htmlFor={`file-${entry.id}`}
                                className="cursor-pointer flex flex-col items-center justify-center p-0.5 border-2 border-dashed border-gray-500 rounded hover:border-blue-400 text-gray-300 text-xs min-h-[50px]"
                            >
                                {entry.file ? (
                                    <div className="w-full">
                                        <div className="text-xs truncate mb-1">{entry.file.name}</div>
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handlePlayPause(entry.id);
                                            }}
                                            className="flex items-center justify-center w-full p-1 bg-blue-500 hover:bg-blue-600 rounded text-white transition-colors text-xs"
                                        >
                                            {playingFile === entry.id ? (
                                                <>
                                                    <FaPause className="mr-1" /> Pause
                                                </>
                                            ) : (
                                                <>
                                                    <FaPlay className="mr-1" /> Play
                                                </>
                                            )}
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <FaFileAudio className="text-2xl mb-1" />
                                        <span className="text-xs">Drop WAV file</span>
                                        <span className="text-xs text-gray-400">or click</span>
                                    </>
                                )}
                            </label>
                        </div>

                        <div>
                            <select
                                value={entry.drumType}
                                onChange={(e) => updateEntry(entry.id, 'drumType', e.target.value)}
                                className="w-full bg-gray-600 text-white rounded px-1 py-0.5 border border-gray-500 text-xs"
                            >
                                <option value="">Type</option>
                                {drumTypes.map(type => (
                                    <option key={type} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <input
                                type="text"
                                placeholder="Genres..."
                                className="w-full bg-gray-600 text-white rounded px-1 py-0.5 border border-gray-500 text-xs"
                                onKeyUp={(e) => handleTagChange(e, (value) => updateEntry(entry.id, 'genres', value), entry.genres)}
                            />
                            <div className="flex flex-wrap gap-0.5 mt-0.5">
                                {entry.genres.map((genre, idx) => (
                                    <span key={idx} className="bg-blue-500 text-white px-1 py-0.5 rounded text-xs">
                                        {genre}
                                        <button onClick={() => handleRemoveTag(entry.id, 'genres', idx)} className="ml-0.5">×</button>
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div>
                            <input
                                type="text"
                                placeholder="Artists..."
                                className="w-full bg-gray-600 text-white rounded px-1 py-0.5 border border-gray-500 text-xs"
                                onKeyUp={(e) => handleTagChange(e, (value) => updateEntry(entry.id, 'artists', value), entry.artists)}
                            />
                            <div className="flex flex-wrap gap-0.5 mt-0.5">
                                {entry.artists.map((artist, idx) => (
                                    <span key={idx} className="bg-blue-500 text-white px-1 py-0.5 rounded text-xs">
                                        {artist}
                                        <button onClick={() => handleRemoveTag(entry.id, 'artists', idx)} className="ml-0.5">×</button>
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div>
                            <input
                                type="text"
                                placeholder="Instruments..."
                                className="w-full bg-gray-600 text-white rounded px-1 py-0.5 border border-gray-500 text-xs"
                                onKeyUp={(e) => handleTagChange(e, (value) => updateEntry(entry.id, 'instruments', value), entry.instruments)}
                            />
                            <div className="flex flex-wrap gap-0.5 mt-0.5">
                                {entry.instruments.map((instrument, idx) => (
                                    <span key={idx} className="bg-blue-500 text-white px-1 py-0.5 rounded text-xs">
                                        {instrument}
                                        <button onClick={() => handleRemoveTag(entry.id, 'instruments', idx)} className="ml-0.5">×</button>
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div>
                            <input
                                type="text"
                                placeholder="Moods..."
                                className="w-full bg-gray-600 text-white rounded px-1 py-0.5 border border-gray-500 text-xs"
                                onKeyUp={(e) => handleTagChange(e, (value) => updateEntry(entry.id, 'moods', value), entry.moods)}
                            />
                            <div className="flex flex-wrap gap-0.5 mt-0.5">
                                {entry.moods.map((mood, idx) => (
                                    <span key={idx} className="bg-blue-500 text-white px-1 py-0.5 rounded text-xs">
                                        {mood}
                                        <button onClick={() => handleRemoveTag(entry.id, 'moods', idx)} className="ml-0.5">×</button>
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div>
                            <input
                                type="text"
                                placeholder="Tempo styles..."
                                className="w-full bg-gray-600 text-white rounded px-1 py-0.5 border border-gray-500 text-xs"
                                onKeyUp={(e) => handleTagChange(e, (value) => updateEntry(entry.id, 'tempoStyle', value), entry.tempoStyle)}
                            />
                            <div className="flex flex-wrap gap-0.5 mt-0.5">
                                {entry.tempoStyle.map((style, idx) => (
                                    <span key={idx} className="bg-blue-500 text-white px-1 py-0.5 rounded text-xs">
                                        {style}
                                        <button onClick={() => handleRemoveTag(entry.id, 'tempoStyle', idx)} className="ml-0.5">×</button>
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div>
                            <input
                                type="number"
                                placeholder="BPM"
                                className="w-full bg-gray-600 text-white rounded px-1 py-0.5 border border-gray-500 text-xs"
                                value={entry.bpm}
                                onChange={(e) => updateEntry(entry.id, 'bpm', e.target.value)}
                            />
                        </div>

                        <div>
                            {entry.drumType === '808' && (
                                <select
                                    className="w-full bg-gray-600 text-white rounded px-1 py-0.5 border border-gray-500 text-xs"
                                    value={entry.key}
                                    onChange={(e) => updateEntry(entry.id, 'key', e.target.value)}
                                >
                                    <option value="">Select Key</option>
                                    {['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'].map(note => (
                                        <option key={note} value={note}>{note}</option>
                                    ))}
                                </select>
                            )}
                        </div>

                        <div>
                            {entry.drumType === '808' && (
                                <select
                                    className="w-full bg-gray-600 text-white rounded px-1 py-0.5 border border-gray-500 text-xs"
                                    value={entry.mode}
                                    onChange={(e) => updateEntry(entry.id, 'mode', e.target.value)}
                                >
                                    <option value="">Select Mode</option>
                                    <option value="Major">Major</option>
                                    <option value="Minor">Minor</option>
                                </select>
                            )}
                        </div>
                    </div>

                    <button
                        onClick={() => removeRow(entry.id)}
                        className="absolute -right-5 top-1/2 transform -translate-y-1/2 text-red-400 hover:text-red-300 bg-transparent"
                    >
                        <FaTimes />
                    </button>
                </div>
            ))}

            <button
                onClick={addNewRow}
                className="w-full mt-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors text-xs"
            >
                Add Another Drum File
            </button>

            <button
                onClick={handleSubmit}
                className="w-full mt-2 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition-colors text-xs"
                disabled={!drumEntries.some(entry => entry.file && entry.drumType)}
            >
                Upload All Files
            </button>
        </div>

        <LoadingPopup isOpen={isLoading} />
        <DrumUploadPopup isOpen={isPopupOpen} onClose={handleClosePopup} />
        <audio ref={audioRef} className="hidden" />
    </div>
);
};

export default DrumUpload;