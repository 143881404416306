import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './LearnMoreAccordion.css';

const LearnMoreAccordion = () => {
    const [isOpen, setIsOpen] = useState(false);
    const accordionRef = useRef(null);

    useEffect(() => {
        // Handler for clicking outside
        const handleClickOutside = (event) => {
            if (accordionRef.current && !accordionRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);
        
        // Cleanup event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleAccordion = (e) => {
        e.stopPropagation(); // Prevent event from bubbling up
        setIsOpen(!isOpen);
    };

    return (
        <div 
            ref={accordionRef}
            className={`learn-more-accordion ${isOpen ? 'open' : ''}`} 
            onClick={toggleAccordion}
        >
            {!isOpen ? (
                <div className="learn-more-closed">
                    <span className="question-mark-icon">?</span> Learn More
                </div>
            ) : (
                <div className="learn-more-open">
                    <ul>
                        <li><Link to="/subscription-features">Subscription Features</Link></li>
                        <li><Link to="/beat-editing">How Beat Editing Works</Link></li>
                        <li><Link to="/rights-usage">Rights & Usage of Music</Link></li>
                        <li><Link to="/ysm-licenses">Licenses & Training Information</Link></li>
                        <li><Link to="/about">About our team</Link></li>
                        <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default LearnMoreAccordion;
